import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        component: () => import('../views/HomeView.vue'),
    },
    {
        path: '/settings',
        component: () => import('../views/SettingsView.vue'),
    },
    {
        path: `/spotify`,
        component: () => import('../views/SpotifyAuth.vue'),
    },
    {
        path: `/song/:isrc`,
        component: () => import('../views/SongView.vue'),
        children: [
            {
                path: ``,
                component: () => import('../views/Song/SongOverview.vue'),
            },
            {
                path: `audio`,
                component: () => import('../views/Song/SongAudio.vue'),
            },
            {
                path: `top`,
                component: () => import('../views/Song/SongStreamers.vue'),
            },
            {
                path: `ratings`,
                component: () => import('../views/Song/SongRatings.vue'),
            },
        ]
    },
    {
        path: `/artist/:id`,
        component: () => import('../views/ArtistView.vue'),
        children: [
            {
                path: ``,
                component: () => import('../views/Artist/ArtistOverview.vue'),
            },
            {
                path: `top`,
                component: () => import('../views/Artist/ArtistStreamers.vue'),
            },
            {
                path: `songs`,
                component: () => import('../views/Artist/ArtistSongs.vue'),
            }
        ]
    },
    {
        path: `/album/:id`,
        component: () => import('../views/AlbumView.vue'),
        children: [
            {
                path: ``,
                component: () => import('../views/Album/AlbumOverview.vue'),
            },
            {
                path: `ratings`,
                component: () => import('../views/Album/AlbumRatings.vue'),
            },
        ]
    },
    {
        path: `/user/:id`,
        component: () => import('../views/ProfileView.vue'),
        children: [
            {
                path: ``,
                component: () => import('../views/Profile/ProfileOverview.vue'),
            },
            {
                path: `streams`,
                component: () => import('../views/Profile/ProfileStreams.vue'),
            },
            {
                path: `songs`,
                component: () => import('../views/Profile/ProfileSongs.vue'),
            },
            {
                path: `artists`,
                component: () => import('../views/Profile/ProfileArtists.vue'),
            },
            {
                path: `ratings`,
                component: () => import('../views/Profile/ProfileRatings.vue'),
            },
        ]
    },
    {
        path: `/rating/:id`,
        component: () => import('../views/RatingView.vue'),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
