import { createStore } from 'vuex'

export default createStore({
    state: {
        apiUrl: `https://tunezy-09c714913487.herokuapp.com`, // https://tunezy-09c714913487.herokuapp.com/
        Account: false
    },
    mutations: {
        SET_ACCOUNT(state, account) {
            state.Account = account
        },
    }
})
