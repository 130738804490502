<template>

    <div id="nav">

        <a id="rave" href="/">Tunezy.app</a>

        <div id="userContainer" v-if="this.$store.state.Account" @click="this.$router.push(`/user/${this.$store.state.Account.username}`)">
            <img :src="this.$store.state.Account.meta.avatar" />
        </div>

    </div>

</template>


<style scoped>

    a {
        position: absolute;
        left: 10%;
        top: 20px;
        font-size: 24px;
        font-weight: bold;
    }

    #nav {
        position: relative;
        width: 100%;
        height: 68px;
        overflow: hidden;
        background-color: #18181C;
        display: flex;
    }

    #userContainer {
        position: absolute;
        top: 14px;
        right: 10%;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
    }

    #userContainer img {
        width: 100%;
        height: 100%;
    }

    @media screen and (max-width: 600px) {

        #userContainer {
            right: 5%;
        }

    }

</style>
