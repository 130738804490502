<template>

    <AppNav />
    <router-view />

</template>

<script>

    import axios from "axios"

    import AppNav from "./components/App/AppNav.vue"

    export default {
        components: {
            AppNav,
        },
        data() {
            return {
                isMobile: false,
            }
        },
        mounted() {
            // Mobile?
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                this.isMobile = true
                this.$store.commit(`SET_MOBILE`)
            }

            // Account data
            const token = localStorage.getItem(`token`)
            if (token) {
                axios.post(`${this.$store.state.apiUrl}/accounts/auth`, {token: token})
                .then((res) => {
                    this.$store.commit(`SET_ACCOUNT`, res.data.account)
                })
                .catch((err) => {
                    console.error(err)
                })
            }
        }
    }
  
</script>


<style>

    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

    body {
        background-color: #111112;
        font-family: 'Montserrat', sans-serif;
        margin: 0px;
    }

    #app {
        text-align: center;
        color: white;
    }

    .icon {
        margin-bottom: 5px;
    }

    #pageContainer {
        width: 100%;
        margin-bottom: 200px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .sectorTop {
        width: 100%;
        padding: 20px 0px;
        background-color: #18181C;
        margin: auto;
        margin-bottom: 10px;
        display: flex;
    }

    #editBracket {
        background-color: #18181C;;
        width: 80%;
        min-width: 350px;
        margin: auto;
        margin-top: 10px;
        padding: 15px;
        border-radius: 6px;
        text-align: left;
    }
    
    #editContainer {
        width: 50%;
        min-width: 320px;
        margin: auto;
        margin-left: 0px;
        margin-top: 30px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        text-align: left;
    }

    #editContainer input {
        width: 100%;
        padding: 10px 5px;
        margin-bottom: 15px;
        font-size: 20px;
        color: white;
        background-color: #09090c;
        border-radius: 5px;
        outline: none;
        border: none;
    }

    #editContainer textArea {
        width: 100%;
        padding: 5px;
        width: 80%;
        margin-bottom: 15px;
        font-size: 20px;
        color: white;
        background-color: #09090c;
        border-radius: 5px;
        outline: none;
    }

    #editContainer select {
        width: 100%;
        padding: 5px;
        margin-bottom: 15px;
        font-size: 20px;
        color: white;
        background-color: #09090c;
        border-radius: 5px;
        outline: none;
    }

    #editContainer h3 {
        margin-bottom: 10px;
    }

    #editContainer p {
        margin-bottom: 10px;
    }
    
    #editCheck {
        margin-left: 25px;
        -ms-transform: scale(2);
        -moz-transform: scale(2);
        -webkit-transform: scale(2);
        -o-transform: scale(2);
        transform: scale(2);
        padding: 10px;
    }

    #editButton {
        width: 100%;
        height: 5vh;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 20px;
        display: flex;
        color: rgb(179, 104, 19);
        border: 1px solid rgb(179, 104, 19);
        border-radius: 5px;
    }

    #editButton:hover {
        cursor: pointer;
        background: rgba(179, 104, 19, 0.144);
    }

    #editButton p {
        margin: auto;
        font-weight: bold;
    }

    #cancelButton {
        width: 100%;
        height: 5vh;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        color: #da3c3c;
        border: 1px solid #da3c3c;
        border-radius: 5px;
    }

    #cancelButton:hover {
        cursor: pointer;
        background: #da3c3c21;
    }

    #cancelButton p {
        margin: auto;
        font-weight: bold;
    }

    #error {
        padding: 5px;
        margin-top: 20px;
        border-radius: 5px;
        background-color: #743117;
    }

    a {
        color: white;
        text-decoration: none;
        cursor: pointer;
    }

    .smallprint {
        color: gray;
    }

    #disclaimer {
        padding: 5px;
        margin-top: 40px;
        border-radius: 5px;
        background-color: #746a17;
    }




    #contentHeader {
        width: 80%;
        margin: auto;
        text-align: left;
        font-size: 24px;
    }

    .sectionGray {
        width: 80%;
        margin: auto;
        margin-top: 5px;
        text-align: left;
        color: #A3A397;
    }

    #contentBlock {
        width: 80%;
        display: flex;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 60px;
        overflow-x: auto;
        overflow-y: hidden;
        scrollbar-width: thin;
        scrollbar-color: rgb(179, 104, 19) rgb(179, 104, 19);
        border-radius: 8px;

        &::-webkit-scrollbar {
            width: 12px;
        }

        &::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: #000000;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgb(179, 104, 19);
            border-radius: 6px;
            border: 3px solid #141414;
        }

        scrollbar-width: thin;
        scrollbar-color: rgb(179, 104, 19) #141414;
        -ms-overflow-style: none;
    }

    #contentBlock > * {
        flex: 0 0 auto;
        margin-right: 15px;
    }

    #contentBlock > :last-child {
        margin-right: 100px;
    }

    #contentBlockRow {
        width: 80%;
        display: flex;
        flex-direction: column;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 60px;
    }

    .contentContainerLong {
        width: 200px;
        padding: 10px 50px;
        margin: 20px;
        background-color: #18181C;
        border-radius: 8px;
        text-align: left;
    }

    .contentContainerLong p {
        color: #A3A397;
        margin-top: -12px;
    }

    .contentContainerSquare {
        width: 180px;
        height: 230px;
        overflow: hidden;
        padding: 10px;
        margin: 20px;
        margin-left: 0px;
        margin-bottom: 30px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        user-select: none;
        cursor: pointer;
        text-align: left;
    }

    .contentContainerSquare img {
        width: 180px;
        height: 180px;
        margin-top: 0px;
        margin-bottom: 10px;
        margin-left: 0px;
        border-radius: 5px;
    }

    .contentContainerSquare h2 {
        margin-left: 0px;
        margin-top: 5px;
        margin-bottom: 2px;
        font-weight: bold;
        font-size: 16px;
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;
    }

    .contentContainerSquare p {
        margin: auto;
        margin-top: 2px;
        margin-left: 0px;
        color: #A3A397;
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;
    }

    #sectorNav {
        width: 80%;
        margin: auto;
        margin-top: 15px;
        margin-bottom: 30px;
        display: flex;
        flex-wrap: wrap;
    }

    .sectorNavButton {
        background-color: #0e0d0d;
        margin: 0px 10px 10px 0px;
        padding: 5px 15px;
        display: flex;
        border-radius: 8px;
        user-select: none;
        cursor: pointer;
    }

    .activeButton {
        background: rgb(179, 104, 19);
    }

    .sectorNavButton p {
        margin: auto;
    }

    #songHeader {
        width: 80%;
        text-align: left;
        margin-left: 30px;
        display: flex;
        flex-direction: column;
    }

    #songname {
        margin: auto;
        margin-left: 0px;
        margin-bottom: 5px;
    }

    #artist {
        margin: auto;
        margin-left: 0px;
        margin-top: 5px;
        font-size: 20px;
        color: #A3A397;
    }

    #songImage {
        margin-left: 10%;
        width: 200px;
        height: 200px;
        border-radius: 5px;
    }

    .sectionMore {
        color: rgb(179, 104, 19);
        width: 80%;
        margin: auto;
        margin-top: 5px;
        text-align: left;
        text-decoration: underline;
    }

    @media screen and (max-width: 600px) {

        .sectorTop {
            flex-direction: column;
            justify-content: center;
        }

        #songHeader {
            text-align: center;
            margin: auto;
        }

        #songname {
            margin: auto;
            margin-top: 15px;
            margin-bottom: 5px;
        }

        #artist {
            margin: auto;
        }

        #songImage {
            margin: auto;
        }

        .contentRanking {
            margin: auto !important;
            margin-top: 20px !important;
        }

    }

    .contentContainerRow {
        width: 100%;
        height: 70px;
        margin-top: 20px;
        background-color: #18181C;
        border-radius: 8px;
        display: flex;
        user-select: none;
        cursor: pointer;
        text-align: left;
    }

    .contentContainerRow img {
        width: 48px;
        height: 48px;
        margin: auto;
        margin-left: 15px;
        margin-right: 10px;
        border-radius: 5px;
    }

    .contentContainerRow p {
        margin: auto;
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;
    }

    .contentContainerRowComment {
        width: 100%;
        margin-top: 20px;
        background-color: #18181C;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        user-select: none;
        cursor: pointer;
        text-align: left;
    }

    .commentInfo {
        width: 100%;
        padding: 15px 15px 0px 15px;
        display: flex;
        position: relative;
    }

    .commentInfo img {
        width: 48px;
        height: 48px;
        margin-left: 5px;
        margin-right: 10px;
        border-radius: 5px;
    }

    .commentName {
        width: 50%;
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;
    }

    .commentRating {
        width: 48px;
        height: 48px;
        display: flex;
        margin-right: 2px;
        text-align: center;
        border-radius: 5px;
    }
    
    .commentRating p {
        font-size: 25px;
        margin: auto;
    }

    .contentRanking {
        background-color: #6868a8;
        width: 48px;
        height: 48px;
        display: flex;
        margin: auto;
        margin-top: 5px;
        margin-left: 2px;
        text-align: center;
        border-radius: 5px;
    }
    
    .contentRanking p {
        font-size: 25px;
        margin: auto;
    }

    #createRatingContainer {
        width: 80%;
        margin: auto;
        margin-bottom: 20px;
        margin-top: -25px;
        display: flex;
        flex-direction: column;
    }

    #createRatingContainer h3 {
        text-align: left;
        font-size: 24px;
        margin-bottom: 5px;
    }

    #createRatingContainer p {
        margin-top: 5px;
        margin-bottom: 15px;
        text-align: left;
        color: #A3A397;
    }

    .bad {
        background-color: #7e2d2d;
    }

    .okay {
        background-color: #a39025;
    }

    .good {
        background-color: #407e2d;
    }

</style>
